import Header from "../../components/header/Header"
import Footer from "../../components/Footer"

const OhMyFood = () => {

    return (
        <>
            <Header />
                <section className="study">
                    <p className="light first">étude de cas</p>
                    <h1 className="study__title">Réservia</h1>
                    <div className="study__container">
                        <div className="study__left">
                            <p className="light">mon rôle</p>
                            <p className="study__left__p">Développement</p>
                            <p className="light">technologie</p>
                            <p className="study__left__p">HTML, CSS</p>
                            <p className="light">client</p>
                            <p className="study__left__p">Projet d'étude</p>
                            <p className="light">année</p>
                            <p className="study__left__p">2021</p>
                        </div>
                        <div className="study__right">
                            <p className="study__right__p"></p>
                            <p className="study__right__p"></p>
                            <p className="study__right__p"></p>
                        </div>
                    </div>
                    <div className="study__img">
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410580/SRDK/ohmyfood/ohmyfood-img1_yl3srw.png" alt="site ohmyfood Mockup macbook." className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410576/SRDK/ohmyfood/ohmyfood-resto-desktop_bhe6q1.png" alt="ohmyfood" className="img__fullScreen"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421938/SRDK/ohmyfood/Mockup_iphone_ohmyfood_um7mtj.png" alt="ohmyfood iphone 12 view" className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421938/SRDK/ohmyfood/ohmyfood_iPhone_12_Mockup_chad7l.png" alt="ohmyfood openclassrooms" className="img__mockup"></img>
                        </div>
                        <div className="study__image study__image__col">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410579/SRDK/ohmyfood/ohmyfood-resto-iphone12-2_zvpvxm.png" alt="ohmyfood" className="img__col"></img>
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410579/SRDK/ohmyfood/ohmyfood-resto-iphone12-3_pjnc1k.png" alt="ohmyfood" className="img__col"></img>
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410576/SRDK/ohmyfood/ohmyfood-resto-iphone12_nxoscz.png" alt="ohmyfood" className="img__col"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421938/SRDK/ohmyfood/tab_ohmyfood_jphazs.png" alt="site ohmyfood Mockup ipad." className="img__mockup"></img>
                        </div>
                    </div>
                </section>               
            <Footer />
        </>
    )
}
export default OhMyFood