import NavPrimary from "../header/NavPrimary"
import NavSecondary from "../header/NavSecondary"


const Header = () => {
    return (
        <div className="header" id="side-bar">        
                <NavPrimary />
                <NavSecondary />
        </div>
    )
}
export default Header
