import { Link } from 'react-router-dom'

const Card = (props) => {

    const { project } = props

    return (
        <Link to={`/portfolio/${project.link}`} className="card__link">
            <li className="card" onClick={project.link}>
                <div className={project.dataStyle}>
                    <h3 className="card__title">{project.title}</h3>
                    <p className="card__myWork">{project.myWork}</p>
                </div>               
                <img src={project.coverUrl} alt={project.imgAlt} className="card__cover" />
            </li>
        </Link>
    )
}
export default Card