import Header from "../components/header/Header"
import Footer from "../components/Footer"

const NotFound = () => {
    return (
        <>
            <Header />
            <div>
                    Désolé ! Cette page n'existe pas.
             </div>
            <Footer />
        </>
    )
}
export default NotFound