import Header from "../components/header/Header"
import Footer from "../components/Footer"
import PortfolioLink from "../components/PortfolioLink"
import Button from "../components/Button"


const About = () => {
    return (
        <div className="About">
            <Header />
                <h1 className="about__title">Je vous accompagne dans le développement de votre projet digital </h1>
                <div className="about__who">
                    <h2 className="about__who__title">Qui suis-je ?</h2>
                    <p className="about__who__text"><span className="bold">Web entrepreneur depuis 2017</span>, je connais la plupart des problématiques auxquelles 
                        vous êtes confronter. Je partage avec vous <span className="bold">mon expérience du digital</span> notamment 
                        la création de site web. Je conçois des sites sur wordpress ou « from scratch » 
                        en codant en HTML, CSS, JavaScript (vanilla ou React.js).</p>
                </div>
                <div className="about__story">
                    <h2 className="about__story__title">Mon parcours</h2>
                    <p className="about__story__text">Lorsque j’ai démarré sur le web, je n’y connaissais absolument rien !<br/><br/>

                        Après plusieurs mois de travail, j’étais encore bien loin des 5000€ mensuel que
                        J’imaginais…<br/><br/>

                        Je pensais qu’avoir un site internet et louer quelques espaces publicitaires
                        Suffirait à faire de moi un homme financièrement indépendant.<br/><br/>

                        Je devais alors comprendre pourquoi ça ne fonctionnait pas. Depuis, je n’ai cessé
                        De me former dans les métiers du web, le e-commerce, amazon FBA, l’affiliation,
                        Le SEO, le web design, le web marketing, et enfin le développement web dans lequel je me suis spécialisé.<br/><br/>

                        Aujourd’hui je veux vous aider dans votre projet, vous faire profiter de mon expérience, de mon expertise, et vous conseiller au mieux pour que votre commerce soit un succès.</p>
                    <div className="about__link">
                        <Button />
                        <PortfolioLink />
                    </div>
                </div>
            <Footer />
        </div>
    )
}
export default About