import Logo from './Logo'

const Footer = () => {
    return (
        <div className="footer">    
            <Logo />    
            <p className="footer__adress">Enregistré sous le nom de madrassa online SARL AU, société de droit marocain, enregistré au registre du commerce de Casablanca sous le numéro 413295.
            <br/><br/>
            46 Bd Zerktouni, 2eme étage, Appart 1,
            Casablanca, Maroc</p>
        </div>
    )
}
export default Footer