import Footer from "../components/Footer";
import Header from "../components/header/Header";
import Button from "../components/Button";
import PortfolioLink from "../components/PortfolioLink";
import Card from "../components/Card";
import { projectData } from "../components/data/ProjectData";

const Home = () => {
    const homeProject = projectData;
    const number = 2;
    const viewProject = homeProject.slice(0, number);

    return (
        <div className="home">
            <Header />
            <section className="value">
                <div className="value__left">
                    <p>Salam Aleykoum ! Je suis Soulayman</p>
                    <h1 className="value__title">
                        Créateur d'expériences modernes,
                        <br /> ultra rapides <br /> et sur mesure
                    </h1>
                    <p>
                        Freelance, <span className="bold">web designer</span> et{" "}
                        <span className="bold">web développeur</span> basé au Maroc
                    </p>
                    <Button />
                    <PortfolioLink />
                </div>
                <div className="value__right">
                    <img
                        className="value__hero"
                        src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1626221264/SRDK/flying-rocket_qeeytg.png"
                        alt="Freelance développeur web srdk digital maroc france"
                    ></img>
                </div>
            </section>
            <section className="view">
                <h2 className="view__title">Aperçu</h2>
                <ul className="view__projects">
                    {viewProject.map((project) => (
                        <Card project={project} key={project._id} />
                    ))}
                </ul>
            </section>
            <section className="step">
                <h2 className="step__title">Étapes clés de la création d'un site web</h2>
                <div className="step__bloc">
                    <p className="step__bloc__number">1</p>
                    <h3 className="step__bloc__title">L'UX Design</h3>
                    <div className="step__flex">
                        <p className="step__bloc__info">
                            C’est concevoir un parcours personnalisée qui répond aux attentes de l’utilisateur. C’est un
                            facteur important de conversion et de fidélisation.
                        </p>
                        <img
                            className="step__bloc__img"
                            src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625498256/SRDK/UX_design-01_inyuhn.png"
                            alt="UX design"
                        ></img>
                    </div>
                </div>
                <div className="step__bloc">
                    <p className="step__bloc__number">2</p>
                    <h3 className="step__bloc__title">L'UI Design</h3>
                    <div className="step__flex step__flex__reverse">
                        <p className="step__bloc__info">
                            C’est concevoir une interface ergonomique et une identité visuelle unique.
                        </p>
                        <img
                            className="step__bloc__img"
                            src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625498258/SRDK/UI_design-01_archv3.png"
                            alt="UX design"
                        ></img>
                    </div>
                </div>
                <div className="step__bloc">
                    <p className="step__bloc__number">3</p>
                    <h3 className="step__bloc__title">Le développement du site ou de l'application</h3>
                    <div className="step__flex">
                        <p className="step__bloc__info">
                            A l’aide de la technologie la plus adéquate, c’est concevoir un site vitrine, e-commerce ou
                            une solution sur-mesure.
                        </p>
                        <img
                            className="step__bloc__img"
                            src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625498249/SRDK/dev-01_m4p4zv.png"
                            alt="UX design"
                        ></img>
                    </div>
                </div>
                <div className="step__bloc">
                    <p className="step__bloc__number">4</p>
                    <h3 className="step__bloc__title">Le web marketing</h3>
                    <div className="step__flex step__flex__reverse">
                        <p className="step__bloc__info">
                            C’est attirer et fidéliser les clients. Ce n’est pas se vendre, c’est se faire désirer.
                        </p>
                        <img
                            className="step__bloc__img"
                            src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625498247/SRDK/marketing_hdotfv.png"
                            alt="UX design"
                        ></img>
                    </div>
                </div>
            </section>
            <div className="call">
                <p className="call__info">
                    Bien que spécialisé dans le web design et le web développement, je peux vous accompagner à chacune
                    de ces étapes et si besoin faire appel à mon réseau pour une expertise.{" "}
                </p>
                <p className="call__question">Besoin d'un site web ?</p>
                <div className="btn--center">
                    <Button />
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default Home;
