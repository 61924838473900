import React, { useState } from "react";

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const ContactForm = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        let nameS = document.getElementById("name");
        let emailS = document.getElementById("email");
        let messageS = document.getElementById("message");
        let formMess = document.querySelector(".form__message");

        const isEmail = () => {
            let isMail = document.getElementById("not-mail");
            let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

            if (email.match(regex)) {
                isMail.style.display = "none";
                return true;
            } else {
                isMail.style.display = "block";
                isMail.style.animation = "dongle 1s";
                setTimeout(() => {
                    isMail.style.animation = "none";
                }, 1000);
                return false;
            }
        };

        if (name && isEmail() && message) {
            nameS.classList.remove("red");
            emailS.classList.remove("red");
            messageS.classList.remove("red");

            formMess.innerHTML = "Message en cours d'envoi...";
            formMess.style.background = "#00c1ec";
            formMess.style.opacity = "1";

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact v1", ...{ name, company, phone, email, message } }),
            }).then(
                () => {
                    formMess.innerHTML = "Message envoyé ! Je vous recontacterai dès que possible.";

                    document.getElementById("name").classList.remove("error");
                    document.getElementById("email").classList.remove("error");
                    document.getElementById("message").classList.remove("error");
                    setName("");
                    setCompany("");
                    setPhone("");
                    setEmail("");
                    setMessage("");

                    setTimeout(() => {
                        formMess.style.opacity = "0";
                    }, 5000);
                },
                (err) => {
                    console.log(err);
                    formMess.style.background = "rgb(253, 87, 87";
                    formMess.innerHTML = "Une erreur s'est produite, veuillez réesseyer.";
                }
            );
        } else {
            formMess.style.background = "rgb(253, 87, 87";
            formMess.innerHTML = "Merci de remplir correctement les champs requis *";
            formMess.style.opacity = "1";

            if (!name) {
                nameS.classList.add("error");
            }
            if (!email) {
                emailS.classList.add("error");
            }
            if (!message) {
                messageS.classList.add("error");
            }
        }
    };

    return (
        <form
            className="contact__form"
            name="contact v1"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit="submit"
            netlify
        >
            <div className="form__content">
                <input type="hidden" name="form-name" value="contact v1" />
                <div hidden>
                    <input name="bot-field" />
                </div>
                <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nom *"
                    value={name}
                    autoComplete="off"
                    className="form__input white"
                    required
                />
                <input
                    type="text"
                    id="company"
                    name="company"
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Société"
                    value={company}
                    autoComplete="none"
                    className="form__input white"
                />
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Téléphone"
                    value={phone}
                    autoComplete="none"
                    className="form__input white"
                />
                <div className="email__content">
                    <label id="not-mail">Email non valide</label>
                    <input
                        type="mail"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Adresse mail *"
                        value={email}
                        autoComplete="none"
                        className="form__input white"
                        required
                    />
                </div>
                <textarea
                    id="message"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Description du projet *"
                    value={message}
                    className="form__area white"
                    rows="8"
                    autoComplete="none"
                    required
                />
            </div>
            <button className="btn" type="submit" value="Envoyer" onClick={handleSubmit}>
                Envoyer
            </button>
            <div className="form__message"></div>
        </form>
    );
};

export default ContactForm;
