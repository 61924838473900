import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../Logo'

class NavPrimary extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked : !this.state.clicked })
    }

    render () {
        return (
            <div className="nav__primary">
                <Logo />
                <div className="nav__primary__icons" onClick={this.handleClick}>
                    <p className={this.state.clicked ? "icon--close" : "icon--menu"}></p>
                </div>
                <div className={this.state.clicked ? "nav__primary__bar active" : "nav__primary__bar"}>
                    <p className="nav__primary__link nav__primary__phone contact__icon2">+212 669-606895</p>
                    <NavLink exact to="/portfolio" className="nav__primary__link" activeClassName="nav__primary__link-active">
                        Portfolio
                    </NavLink>
                    <NavLink exact to="/a-propos" className="nav__primary__link" activeClassName="nav__primary__link-active">
                        À propos
                    </NavLink>
                    <NavLink exact to="/contact" className="nav__primary__link" activeClassName="nav__primary__link-active">Contact</NavLink>
                </div>
            </div>    
        )
    }
}

export default NavPrimary