import Header from "../../components/header/Header"
import Footer from "../../components/Footer"

const Reservia = () => {
    return (
        <>
            <Header />
            <section className="study">
                    <p className="light first">étude de cas</p>
                    <h1 className="study__title">Réservia</h1>
                    <div className="study__container">
                        <div className="study__left">
                            <p className="light">mon rôle</p>
                            <p className="study__left__p">Développement</p>
                            <p className="light">technologie</p>
                            <p className="study__left__p">HTML, CSS</p>
                            <p className="light">client</p>
                            <p className="study__left__p">Projet d'étude</p>
                            <p className="light">année</p>
                            <p className="study__left__p">2021</p>
                        </div>
                        <div className="study__right">
                            <p className="study__right__p"></p>
                            <p className="study__right__p"></p>
                            <p className="study__right__p"></p>
                        </div>
                    </div>
                    <div className="study__img">
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410634/SRDK/Reservia/reservia-img1_vmud40.png" alt="site reservia Mockup macbook." className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421910/SRDK/Reservia/fullscreen_desktop_reservia_cjnzy8.png" alt="reservia" className="img__fullScreen"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421905/SRDK/Reservia/Mockup_iphone_reservia_hxvcba.png" alt="reservia iphone 12 view" className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421906/SRDK/Reservia/fullscreen_mobile_reservia_ukxbnh.png" alt="reservia" className="img__fullScreen__mobile"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421905/SRDK/Reservia/iPhone_12_Mockup_RESERVIA_nxbgtw.png" alt="reservia openclassrooms" className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625421906/SRDK/Reservia/iPad_reservia_h2znke.png" alt="site reservia Mockup ipad." className="img__mockup"></img>
                        </div>
                    </div>
            </section> 
            <Footer />
        </>
    )
}
export default Reservia