
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from "./pages/Home"
import About from "./pages/About"
import Portfolio from "./pages/Portfolio"
import NotFound from "./pages/NotFound"
import Zahra_F from './pages/portfolio/Zahra_F';
import Reservia from './pages/portfolio/Reservia';
import Cream_B from './pages/portfolio/Cream_B';
import OhMyFood from './pages/portfolio/OhMyFood';
import Contact from './pages/Contact';


const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/a-propos" exact component={About} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/portfolio/zahra-f" exact component={Zahra_F}/>
        <Route path="/portfolio/reservia" exact component={Reservia}/>
        <Route path="/portfolio/cream-b" exact component={Cream_B}/>
        <Route path="/portfolio/ohmyfood" exact component={OhMyFood}/>
        <Route exact component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
