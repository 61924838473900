import Header from "../../components/header/Header"
import Footer from "../../components/Footer"

const ZahraF = () => {
    return (
        <>
            <Header />
            <section className="study">
                    <p className="light first">étude de cas</p>
                    <h1 className="study__title">Zahra F.</h1>
                    <div className="study__container">
                        <div className="study__left">
                            <p className="light">mon rôle</p>
                            <p className="study__left__p">Design, Développement</p>
                            <p className="light">technologie</p>
                            <p className="study__left__p">React, Sass</p>
                            <p className="light">client</p>
                            <p className="study__left__p">Zahra F.</p>
                            <p className="light">année</p>
                            <p className="study__left__p">2021</p>
                        </div>
                        <div className="study__right">
                            <p className="study__right__p">Zahra F. est une papèterie. Ils design des carnets, des cahiers et des agendas. Ils les vendent en utilisant le programme d’auteur KDP.
                            </p>
                            <p className="study__right__p">La société avait besoin d’une présence en ligne pour accompagner son évolution sur les réseaux sociaux et pour développer un programme d’affiliation via Amazon. Sur ce projet, j’ai designé le site web en me servant des couleurs de la marque.
                            </p>
                            <p className="study__right__p">J’avais un libre choix dans le design et le choix de technologie du site web. J’ai sélectionner la librairie React pour coder ce site car React est très rapide. Et également pour développer mes compétences sur cette technologie.
                            </p>
                        </div>
                    </div>
                    <div className="study__img">
                        <div className="study__image study__img1">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410648/SRDK/zahraf/zahra_f-img1_jpbmr7.png" alt="site zahra f developpé en react. Mockup macbook." className="img__mockup"></img>
                        </div>
                        <div className="study__image study__img2">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto/v1625410649/SRDK/zahraf/zahra_f-performances_gccuki.png" alt="zahra f performance" className="img__performance"></img>
                        </div>
                        <div className="study__image study__img3">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625412066/SRDK/zahraf/zahraf-iphone12-m1_tcuggm.png" alt="zahra f papèterie, carnet, cahier, livre pour les femmes" className="img__mockup"></img>
                        </div>
                        <div className="study__image study__img4">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625412779/SRDK/zahraf/zahraf_complet_desktop_p6fz0o.png" alt="zahra f papèterie, carnet, cahier, livre pour les femmes" className="img__fullScreen"></img>
                        </div>
                        <div className="study__image study__img5">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625412245/SRDK/zahraf/zahraf-iphone12-m2_n6ahce.png" alt="zahra f papèterie, carnet, cahier, livre pour les femmes" className="img__mockup"></img>
                        </div>
                        <div className="study__image study__img6">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625412554/SRDK/zahraf/zahraf-ipad_knhlep.png" alt="zahra f papèterie, carnet, cahier, livre pour les femmes" className="img__mockup"></img>
                        </div>
                    </div>
                </section> 
            <Footer />
        </>
    )
}
export default ZahraF