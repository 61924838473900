import React from "react";
import ContactFormNet from "../components/ContactFormNet";
import Footer from "../components/Footer";
import Header from "../components/header/Header";

const Contact = () => {
    return (
        <div>
            <Header />
            <section className="contact">
                <h1 className="contact__title">Travaillons ensemble !</h1>
                <div className="contact__container">
                    <p className="contact__info">Remplissez ce formulaire de contact.</p>
                    <ContactFormNet />
                    <br />
                    <p className="contact__info">
                        Vous êtes une agence ou freelance ? Je suis ouvert à toutes collaborations.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Contact;
