import Header from "../../components/header/Header"
import Footer from "../../components/Footer"

const CreamB = () => {
    return (
        <>
            <Header />
            <section className="study">
                    <p className="ligh first">étude de cas</p>
                    <h1 className="study__title">Cream B</h1>
                    <div className="study__container">
                        <div className="study__left">
                            <p className="light">mon rôle</p>
                            <p className="study__left__p">Développement</p>
                            <p className="light">technologie</p>
                            <p className="study__left__p">Wordpress</p>
                            <p className="light">client</p>
                            <p className="study__left__p">Concepteur Cream B</p>
                            <p className="light">année</p>
                            <p className="study__left__p">2021</p>
                        </div>
                        <div className="study__right">
                            <p className="study__right__p">Concepteur Cream B est une marque de pate à tartiner.</p>
                            <p className="study__right__p">La propriétaire de cette marque possédait un site shopify et voulait diminuer ses coûts en migrant sur Wordpress.</p>
                            <p className="study__right__p">Je me suis basé sur le design existant que j'ai modifié pour une meilleure accéssibilité.</p>
                        </div>
                    </div>
                    <div className="study__img">
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410467/SRDK/creamB/Cream_B-img1_pxufoa.png" alt="site concepteur cream B Mockup macbook." className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625410489/SRDK/creamB/cream_b-entier_bdxnnw.png" alt="CREAM B pate à tartiner wordpress" className="img__fullScreen"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625417683/SRDK/creamB/creamB-iphone12-m1_ljpzks.png" alt="concepteur cream B" className="img__mockup"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625418291/SRDK/creamB/iPhone_12_Mockup_p3czxn.png" alt="concepteur cream B iphone 12 view" className="img__mockup"></img>
                        </div>
                        <div className="study__image study__image__col">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625418774/SRDK/creamB/creamB_screen2_rybmfg.png" alt="concepteur cream B" className="img__col"></img>
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625418771/SRDK/creamB/creamB_screen_1_wuy3pg.png" alt="concepteur cream B" className="img__col"></img>
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625418771/SRDK/creamB/creamB_screen3_kelhas.png" alt="concepteur cream B" className="img__col"></img>
                        </div>
                        <div className="study__image">
                            <img src="https://res.cloudinary.com/dixakpeoz/image/upload/f_auto,q_auto/v1625418078/SRDK/creamB/creamB-ipad_e2qmql.png" alt="site concepteur cream B Mockup ipad." className="img__mockup"></img>
                        </div>
                    </div>
                </section> 
            <Footer />
        </>
    )
}
export default CreamB