export const projectData = [
    {
        _id: 1,
        title: 'Zahra F.',
        category: 'Site web',
        subCategory: 'Work',
        myWork: 'Design, Développement',
        technology: 'React.js, SASS',
        client: 'Zahra F.',
        year: '2021',
        coverUrl: 'https://res.cloudinary.com/dixakpeoz/image/upload/f_auto/v1625439580/SRDK/zahraf/zahraF-website_zg8lyw.png',
        imgAlt: '',
        link: 'zahra-f',
        dataStyle: 'card__data card__data__color1'
    },
    {
        _id: 2,
        title: 'Réservia',
        category: 'Site web',
        subCategory: 'Etude',
        myWork: 'Développement',
        technology: 'HTML, CSS',
        client: 'Zahra F.',
        year: '2021',
        coverUrl: 'https://res.cloudinary.com/dixakpeoz/image/upload/f_auto/v1625441145/SRDK/Reservia/reservia-website_rx0nvu.png',
        imgAlt: '',
        link: 'reservia',
        dataStyle: 'card__data card__data__color2'
    },
    {
        _id: 3,
        title: 'Cream B',
        category: 'Site web',
        subCategory: 'Work',
        myWork: 'Développement',
        technology: 'Wordpress',
        client: 'Zahra F.',
        year: '2021',
        coverUrl: 'https://res.cloudinary.com/dixakpeoz/image/upload/f_auto/v1625445163/SRDK/creamB/creamB-website_svchsb.png',
        imgAlt: '',
        link: 'cream-b',
        dataStyle: 'card__data card__data__color3'
    },
    {
        _id: 4,
        title: 'OhMyFood',
        category: 'Site web',
        subCategory: 'Etude',
        myWork: 'Développement',
        technology: 'HTML, SASS',
        client: 'Zahra F.',
        year: '2021',
        coverUrl: 'https://res.cloudinary.com/dixakpeoz/image/upload/f_auto/v1625443666/SRDK/ohmyfood/ohmyfood-website_xo5dcg.png',
        imgAlt: '',
        link: 'ohmyfood',
        dataStyle: 'card__data card__data__color4'
    },
]