import React from 'react'
import { Link } from 'react-router-dom'

const NavSecondary = () => {
    return (
        <div className="nav__secondary">
            <Link exact to="/portfolio" className="nav__secondary__link portfolio__icon">
                Portfolio
            </Link>
            <Link exact to="/contact" className="nav__secondary__link contact__icon">
                Contact
            </Link>
        </div>
    )
}
export default NavSecondary