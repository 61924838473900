import { projectData } from "../components/data/ProjectData"
import Footer from "../components/Footer"
import Header from "../components/header/Header"
import Card from '../components/Card'

const Portfolio = () => {
    
    return (
        <div className="portfolio">
            <Header />
            <h1 className="portfolio__title">Études de cas</h1>
            <p className="portfolio__description">Sélection de projets</p>
            <ul className='portfolio__list'>
                {projectData.map((project) => (
                    <Card project={project} key={project.id} />
                ))}
            </ul>
            <Footer />
        </div>
    )
}
export default Portfolio